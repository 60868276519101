import React from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

class PageTitle extends React.Component {
    render() {
        return <div className="row mb-5">
            <div className="col-12 ">
                <header className="page-title">
                    <h1>
                        {this.props.children}
                    </h1>
                </header>
            </div>
        </div>
    }
}

class Container extends React.Component {
    render() {
        return <div className="container">
            {this.props.children}
        </div>
    }
}

class Footer extends React.Component {
    render() {
        return [
            <div className="row mb-5 mt-5" key="1">
                <div className="col-12 trailer">
                    <p>
                        For general <a href="https://mobiushosting.net/" target="_blank">Web Hosting</a>,&nbsp;
                        <a href="https://mobiushosting.net/" target="_blank">Email Hosting</a> and&nbsp;
                        <a href="https://mobiushosting.net/" target="_blank">Domain Hosting</a>&nbsp;
                        head on over to&nbsp;
                        <a href="https://mobiushosting.net/" target="_blank">Mobius Hosting</a>&nbsp;
                        - plans start from $5/month only
                    </p>
                </div>
            </div>,
            <div className="row mb-5" key="2">
                <div className="col-12">
                    <footer>
                        <p>
                            <Link to={this.props.linker.home()}>Home</Link>
                            <Link to={this.props.linker.pricing()}>Pricing</Link>
                            <Link to={this.props.linker.about()}>About Us</Link>
                        </p>
                    </footer>
                </div>
            </div>
        ]
    }
}

Footer.propTypes = {
    linker: PropTypes.func
};

class Menu extends React.Component {
    render() {
        return <div className="mb-5">
            <div
                className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow">
                <h5 className="my-0 mr-md-auto font-weight-normal">
                    <Link className="text-dark" to={this.props.linker.home()}>Xtreme VPS</Link>
                </h5>
                <nav className="my-2 my-md-0 mr-md-3">
                    <Link className="p-2 text-dark" to={this.props.linker.about()}>About Us</Link>
                    <Link className="p-2 text-dark" to={this.props.linker.pricing()}>Pricing</Link>
                </nav>
            </div>
        </div>
    }
}

Menu.propTypes = {
    linker: PropTypes.func
};

export {
    Menu,
    Container,
    Footer,
    PageTitle
}