import React from "react";
import {InvitationForm} from "./Forms";
import {PageTitle} from "./Components";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";


class HomePage extends React.Component {
    render() {
        return [
            <PageTitle key="1">Affordable, Unmanaged Linux Virtual Private Servers</PageTitle>,

            <div className="row" key="2">
                <div className="col-6">
                    <p>
                        Finally, a Linux Virtual Private Server (VPS) provider that actually cares about performance and
                        does not break the piggy bank.
                    </p>
                    <p>
                        Here's just a glimpse on how we do things differently from other VPS providers:
                    </p>
                    <ul>
                        <li>Linux Kernel-based Virtual Machine (KVM) virtualisation technology</li>
                        <li>No overselling of memory and disk</li>
                        <li>We monitor, and try our best to minimise our host server CPU utilisation</li>
                        <li>Linux Volume Manager (LVM) for better disk I/O performance</li>
                        <li>Plenty of disk space and bandwidth</li>
                        <li>Ability to provision VPS instances globally</li>
                    </ul>
                    <p>
                        <Link to={this.props.linker.pricing()}>Plans begin from only $12.50 a month</Link>
                    </p>
                </div>
                <div className="col-6">
                    <h2>
                        We are currently in private beta
                    </h2>
                    <p>
                        However, you can join our private beta programme:
                    </p>
                    <InvitationForm/>
                    <p>
                        We send out invitation codes every couple of weeks, so keep a look out
                        for an email from hello@xtremevps.net.
                    </p>
                    <p>
                        We respect your privacy and won't sell or divulge your email to anyone. Heck, we won't even
                        spam you. Just one email with an invite code and some instructions.
                    </p>
                </div>
            </div>
        ]
    }
}
HomePage.propTypes = {
    linker: PropTypes.func
};

class AboutPage extends React.Component {
    render() {
        return [
            <PageTitle key="1">About Us</PageTitle>,

            <div className="row" key="2">
                <div className="col-12">
                    <h1>About Us</h1>
                    <p>
                        We are <a href="http://exentriquesolutions.com/" target="_blank">Exentrique Solutions</a>.
                    </p>
                    <p>
                        We build online services. Our flagship product is <a href="https://xp-dev.com"
                                                                             target="_blank">XP-Dev.com</a>&nbsp;
                        which offers hosted <a href="https://xp-dev.com/features/svn-hosting.html"
                                               target="_blank">Subversion</a>,&nbsp;
                        <a href="https://xp-dev.com/features/git-hosting.html" target="_blank">Git</a> and&nbsp;
                        <a href="https://xp-dev.com/features/mercurial-hosting.html"
                           target="_blank">Mercurial</a> repositories,&nbsp;
                        and includes agile project management tools (as well as <a
                        href="https://xp-dev.com/features/trac-hosting.html" target="_blank">Trac hosting</a>).
                    </p>
                    <p>
                        <Link to={this.props.linker.home()}>Join our private beta programme</Link>
                    </p>
                </div>
            </div>
        ]
    }
}
AboutPage.propTypes = {
    linker: PropTypes.func
};

class PricingPage extends React.Component {
    render() {
        return [
            <PageTitle key="1">Pricing</PageTitle>,

            <div className="row" key="2">
                <div className="col-4">
                    <p>
                        We haven't settled on any pricing plans yet, but here's what we were thinking of charging once we launch.
                    </p>
                    <p>VPS instances can be launch in the following regions:</p>
                    <ul>
                        <li>Germany, EU</li>
                        <li>East Coast US (Philadelphia, PA)</li>
                        <li>West Coast US (Phoenix, AZ)</li>
                        <li>Asia and Australia will be coming soon</li>
                    </ul>
                </div>
                <div className="col-8">
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Plan</th>
                            <th scope="col">VPS-S</th>
                            <th scope="col">VPS-M</th>
                            <th scope="col">VPS-L</th>
                            <th scope="col">VPS-XL</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td scope="row">Memory</td>
                            <td scope="row">512MB</td>
                            <td scope="row">1024MB</td>
                            <td scope="row">2048MB</td>
                            <td scope="row">4096MB</td>
                        </tr>
                        <tr>
                            <td scope="row">Disk Space</td>
                            <td scope="row">25GB</td>
                            <td scope="row">50GB</td>
                            <td scope="row">100GB</td>
                            <td scope="row">200GB</td>
                        </tr>
                        <tr>
                            <td scope="row">Bandwidth</td>
                            <td scope="row">200GB</td>
                            <td scope="row">400GB</td>
                            <td scope="row">800GB</td>
                            <td scope="row">1500GB</td>
                        </tr>
                        <tr>
                            <td scope="row">Monthly $USD Price</td>
                            <td scope="row">$12.50</td>
                            <td scope="row">$25.00</td>
                            <td scope="row">$50.00</td>
                            <td scope="row">$100.00</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        ]
    }
}

export {
    PricingPage,
    AboutPage,
    HomePage
}