import React from "react";
import ReactDOM from "react-dom"
import {BrowserRouter as Router, Route} from "react-router-dom"
// noinspection ES6UnusedImports
import Bootstrap from 'bootstrap/dist/css/bootstrap.css';
// noinspection ES6UnusedImports
import css from "app.css"
// noinspection ES6UnusedImports
import freelanceCss from "freelancer.css"
import {Container, Footer, Menu} from "./Components";
import {AboutPage, HomePage, PricingPage} from "./Pages";


class Linker {
    static about() {
        return "/about"
    }

    static pricing() {
        return "/pricing"
    }

    static home() {
        return "/"
    }
}


const root = <Router>
    <div>
        <Menu linker={Linker}/>
        <Container>
            <Route exact={true} path={Linker.home()} render={props => {
                return <HomePage linker={Linker} {...props}/>
            }}/>
            <Route path={Linker.about()} render={props => {
                return <AboutPage linker={Linker} {...props}/>
            }}/>
            <Route path={Linker.pricing()} component={PricingPage}/>
            <Footer linker={Linker}/>
        </Container>
    </div>
</Router>;

ReactDOM.render(
    root,
    document.getElementById("root")
);



