import React from "react";
import jQuery from "jquery";

class InvitationForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            feedback: ""
        };
    }

    static cancel(event) {
        if (event != null && "preventDefault" in event) {
            event.preventDefault();
        }
    }

    handleResponse() {
        return response => {
            if (response.success) {
                this.setState({email: ""});
                this.setFeedback("Thanks for joining. We will be in touch shortly.");
            } else {
                console.error("Error", response);
                this.setFeedback("Failed to join");
            }
        }
    }

    setFeedback(feedback) {
        this.setState({feedback: feedback})
    }

    submit() {
        return event => {
            InvitationForm.cancel(event);

            const email = this.state.email.trim();

            if (email.length === 0) {
                this.setFeedback("Email is invalid");
                return;
            }

            jQuery.post({
                url: "/api/v1/invitation/signup",
                data: JSON.stringify({email: email}),
                dataType: "json",
                contentType: "application/json"
            }).then(this.handleResponse());
        }
    }

    emailChanged() {
        return event => {
            this.setState({email: event.target.value})
        }
    }

    render() {
        return <div>
            <form onSubmit={this.submit()}>
                <div className="form-group">
                    <label htmlFor="email">Your email address</label>
                    <input type="email" className="form-control" id="email" name="name" placeholder="Email address"
                           onChange={this.emailChanged()} value={this.state.email}/>
                </div>
                <div className="form-group">
                    <button type="submit" className="btn btn-primary">Sign Up</button>
                </div>
                <p className="form-feedback">{this.state.feedback}</p>
            </form>
        </div>
    }
}

export {
    InvitationForm
}